import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_PROFILE_NOTIFICATION = gql`
  mutation SubscribeToProfileNotification(
    $profileIds: [Int!]!
    $email: String!
    $subscribeToNewReviews: Boolean!
    $subscribeToNewJobDescriptions: Boolean!
    $targetPageAfterLogin: SubscribeToProfileTargetPageAfterLogin
  ) {
    subscribeToProfileNotification(
      profileIds: $profileIds
      email: $email
      subscribeToNewReviews: $subscribeToNewReviews
      subscribeToNewJobDescriptions: $subscribeToNewJobDescriptions
      targetPageAfterLogin: $targetPageAfterLogin
    ) {
      success
      userAccountExists
    }
  }
`;
