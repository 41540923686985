import { RefObject, useEffect, useState } from 'react';

const minimalTimeOfVisibilityInMilliseconds = 500;

const useElementInViewport = (ref: RefObject<any>): boolean => {
  const [isElementInViewPort, setIsElementInViewPort] = useState(false);

  useEffect(() => {
    let lastTimeout: ReturnType<typeof setTimeout> | undefined;
    let lastIsIntersecting: boolean | undefined;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting !== lastIsIntersecting) {
          if (lastTimeout) {
            clearTimeout(lastTimeout);
          }

          lastTimeout = setTimeout(() => {
            setIsElementInViewPort(entry.isIntersecting);
          }, minimalTimeOfVisibilityInMilliseconds);

          lastIsIntersecting = entry.isIntersecting;
        }
      },
      { threshold: 0.5 },
    );

    ref.current && observer.observe(ref.current);

    return () => {
      ref.current && observer.unobserve(ref.current);
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
    };
  }, []);

  return isElementInViewPort;
};

export default useElementInViewport;
