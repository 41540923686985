import classNames from 'classnames';
import React from 'react';
import GraphBar from '@components/utilities/GraphBar/GraphBar';
import classes from './Graph.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  label?: string;
  percentage: number;
  subLabel?: string;
}

const Graph = ({ className, label, percentage, subLabel, ...divProps }: Props) => (
  <div className={classNames(classes.Graph, className)} {...divProps}>
    <div className={classes.Graph__description}>
      {label && (
        <span>
          {label}
          {subLabel && <span className={classes.Graph__subLabel}>{subLabel}</span>}
        </span>
      )}
      <span className={classes.Graph__percentage}>{`${percentage} %`}</span>
    </div>
    <GraphBar percentage={percentage} />
  </div>
);

export default Graph;
