const CLOUDIMAGE_MANDATORY_PARAMETER = 'org_if_sml';

export const addMaxResolutionToImageUrl = (imageUrl: string, maxWidth: number): string => {
  const url = new URL(imageUrl);

  if (!url.searchParams.has('width')) {
    url.searchParams.append('width', maxWidth.toString());
  }
  if (!url.searchParams.has(CLOUDIMAGE_MANDATORY_PARAMETER)) {
    url.searchParams.append(CLOUDIMAGE_MANDATORY_PARAMETER, '1');
  }

  return url.toString();
};
const imgSrcAttributesWithMaxResolution = (imageUrl: string, maxWidth: number) => {
  return {
    src: addMaxResolutionToImageUrl(imageUrl, maxWidth),
    srcSet: `${addMaxResolutionToImageUrl(imageUrl, 2 * maxWidth)} 2x`,
  };
};

export default imgSrcAttributesWithMaxResolution;
