import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import useElementInViewport from '@hooks/useElementInViewport';
import { ImpressionListEventType } from '@libs/Enum/ImpressionLists';
import pushGACompanyImpressionListEvent from '@libs/ga/pushGACompanyImpressionListEvent';
import classes from './ProfileCard.module.scss';
import { ProfileCardData } from '../../../graphql/generated/types/graphqlTypes';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  profileCardData: ProfileCardData;
  impressionListName: string;
  impressionListVariant: string;
  position: number;
  children: ReactNode;
  additionalGAImpressionListParameters?: {};
}

const ProfileCardContainer = ({
  profileCardData,
  impressionListName,
  impressionListVariant,
  children,
  className,
  position,
  additionalGAImpressionListParameters,
  ...divProps
}: Props) => {
  const IMPRESSION_LIST_MATRIX_ID = 96;

  const { i18n } = useTranslation(['messages', 'profile']);

  const [sentGaImpressionEvent, setGaImpressionEventSent] = useState(false);

  const ref = useRef(null);
  const elementInViewport = useElementInViewport(ref);
  useEffect(() => {
    if (elementInViewport) {
      pushGACompanyImpressionListEvent(
        ImpressionListEventType.view,
        profileCardData.profile,
        impressionListName,
        impressionListVariant,
        i18n.language,
        position,
        undefined,
        additionalGAImpressionListParameters || {},
      );
      setGaImpressionEventSent(true);
    }
  }, [elementInViewport || sentGaImpressionEvent]);

  return (
    <div
      className={classNames(classes.ProfileCard, className)}
      key={`profile_card_${profileCardData.profile.urlIdentifier}`}
      ref={ref}
      data-gtm-matrixID={IMPRESSION_LIST_MATRIX_ID}
      {...divProps}
    >
      {children}
    </div>
  );
};

export default ProfileCardContainer;
