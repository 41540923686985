import classNames from 'classnames';
import React from 'react';
import classes from './GraphBar.module.scss';

export enum GraphBarOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

type GraphBarOrientationType = GraphBarOrientation.horizontal | GraphBarOrientation.vertical;

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  orientation?: GraphBarOrientationType;
  percentage: number;
}

const GraphBar = ({ className, orientation = GraphBarOrientation.horizontal, percentage }: Props) => {
  return (
    <div
      className={classNames(
        classes.GraphBar,
        orientation === GraphBarOrientation.vertical ? classes.GraphBarVertical : '',
        className,
      )}
    >
      <span
        style={
          orientation === GraphBarOrientation.horizontal ? { width: `${percentage}%` } : { height: `${percentage}%` }
        }
      />
    </div>
  );
};

export default GraphBar;
