import { Alert, Icon, IconsProvider } from '@lmc-eu/spirit-web-react';
import { SpiritAlertProps } from '@lmc-eu/spirit-web-react/types';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { alertIcons } from '@alertIcons';
import classes from './NextAlert.module.scss';

interface Props extends SpiritAlertProps {
  children: ReactNode;
  className?: string;
  customOnClickActionCallback?: () => void;
  isClosable?: boolean;
}

const NextAlert = ({ children, className, customOnClickActionCallback, isClosable = false, ...alertProps }: Props) => {
  const [showFlashMessage, setShowFlashMessage] = useState<boolean>(true);

  if (isClosable) {
    if (!showFlashMessage) {
      return null;
    }

    const onClickAction = () => {
      if (customOnClickActionCallback) {
        customOnClickActionCallback();
      }
      setShowFlashMessage(false);
    };

    return (
      <IconsProvider value={alertIcons}>
        <Alert UNSAFE_className={classNames(classes.NextAlert, className)} {...alertProps}>
          <div className="d-flex">
            <div className={classes.NextAlert__content}>{children}</div>
            <div onClick={onClickAction} className={classes.NextAlert__close}>
              <Icon name="cross" size={24} />
            </div>
          </div>
        </Alert>
      </IconsProvider>
    );
  }

  return (
    <IconsProvider value={alertIcons}>
      <Alert UNSAFE_className={classNames(classes.NextAlert, className)} {...alertProps}>
        {children}
      </Alert>
    </IconsProvider>
  );
};

export default NextAlert;
