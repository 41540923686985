export enum ImpressionLists {
  JOBS_LIST = 'jobs list',
  PREMIUM_JOBS_LIST_BOTTOM = 'premium jobs list bottom',
  INTERESTING_COMPANIES = 'zajimave-firmy',
  INTERESTING_COMPANIES_JOBS_SERP = 'interesting companies jobs SERP',
}

export enum ImpressionListTagSuitabilityPresent {
  HAS_SUITABILITY = 'has_suitability',
  WITHOUT_SUITABILITY = 'without_suitability',
}

export enum ImpressionListEventType {
  view = 'view',
  click = 'click',
}
