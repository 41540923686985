import { ImpressionListEventType } from '@libs/Enum/ImpressionLists';
import { JobDescriptionLink } from '../../graphql/generated/types/graphqlTypes';

const pushGAJobOpportunityImpressionListEvent = (
  action: ImpressionListEventType,
  jobOpportunity: JobDescriptionLink,
  impressionListName: string,
  impressionListCompanyVariant: string,
  position: number,
  tags = 'missing',
  additionalGAImpressionListParameters: object = {},
) => {
  const dataLayer = window.dataLayer || [];
  const eventName = `job_posting_impression_${action}`;
  dataLayer.push({
    event: eventName,
    hit: {
      job_posting_id: jobOpportunity.g2id.toString(),
      job_posting_name: jobOpportunity.title,
      job_posting_affiliation: jobOpportunity.impressionListVariant ?? 'missing',
      job_posting_category: jobOpportunity.impressionListCategory ?? 'missing',
      job_posting_tags: tags,
      company_name: jobOpportunity.brandForGoogleAnalytics
        ? jobOpportunity.brandForGoogleAnalytics.split(/\d+$/)![0]?.trim()
        : 'missing',
      company_id: jobOpportunity.brandForGoogleAnalytics
        ? jobOpportunity.brandForGoogleAnalytics.match(/\d+$/)![0]?.trim()
        : 'missing',
      company_variant: impressionListCompanyVariant,
      action: action.toString(),
      link_url: action === ImpressionListEventType.click ? jobOpportunity.externalDetailUrl : undefined,
      list_name: impressionListName,
      list_position: position.toString(),
      ...additionalGAImpressionListParameters,
    },
    _clear: true,
  });
};

export default pushGAJobOpportunityImpressionListEvent;
