import React, { useEffect, useRef, useState } from 'react';
import useElementInViewport from '@hooks/useElementInViewport';
import { ImpressionListEventType } from '@libs/Enum/ImpressionLists';
import pushGAJobOpportunityImpressionListEvent from '@libs/ga/pushGAJobOpportunityImpressionListEvent';
import { JobDescriptionLink } from '../../../graphql/generated/types/graphqlTypes';

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  jobOpportunity: JobDescriptionLink;
  impressionListName: string;
  impressionListCompanyVariant: string;
  position: number;
}
const ProfileCardInterestingJobDescriptionLink = ({
  jobOpportunity,
  impressionListName,
  impressionListCompanyVariant,
  position,
  ...aProps
}: Props) => {
  const [sentGaImpressionEvent, setSentGaImpressionEvent] = useState(false);
  const ref = useRef(null);
  const elementInViewport = useElementInViewport(ref);

  useEffect(() => {
    if (elementInViewport) {
      pushGAJobOpportunityImpressionListEvent(
        ImpressionListEventType.view,
        jobOpportunity,
        impressionListName,
        impressionListCompanyVariant,
        position,
      );
      setSentGaImpressionEvent(true);
    }
  }, [elementInViewport || sentGaImpressionEvent]);

  const sendGaClickedOnJobOpportunity = () => {
    pushGAJobOpportunityImpressionListEvent(
      ImpressionListEventType.click,
      jobOpportunity,
      impressionListName,
      impressionListCompanyVariant,
      position,
    );
  };

  return (
    <a ref={ref} onClick={sendGaClickedOnJobOpportunity} {...aProps}>
      {aProps.children}
    </a>
  );
};

export default ProfileCardInterestingJobDescriptionLink;
